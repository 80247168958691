<mat-toolbar color="primary" class="app-toolbar no-print">
  <mat-toolbar-row>
    <div id="app-nav-nodes" role="navigation">
      <ul>
        <li>
          <a class="nav-link" routerLink="{{navInfo.get('courses')!.url}}" [title]="navInfo.get('courses')!.title">
            <span class="nav-link-inner">{{ navInfo.get('courses')!.title }}</span>
          </a>
        </li>
        <li fxHide fxShow.gt-xs>
          <a class="nav-link" routerLink="{{navInfo.get('chart')!.url}}" [title]="navInfo.get('chart')!.title">
            <span class="nav-link-inner">{{ navInfo.get('chart')!.title }}</span>
          </a>
        </li>
        <li fxHide fxShow.gt-xs>
          <a class="nav-link" routerLink="{{navInfo.get('reviews')!.url}}" [title]="navInfo.get('reviews')!.title">
            <span class="nav-link-inner">{{ navInfo.get('reviews')!.title }}</span>
          </a>
        </li>
        <li fxFlex></li>
        <li fxHide fxShow.gt-xs *ngIf="isLoggedIn">
          <a class="nav-link" routerLink="{{navInfo.get('createReview')!.url}}" [title]="navInfo.get('createReview')!.title">
            <span class="nav-link-inner">{{ navInfo.get('createReview')!.title }}</span>
          </a>
        </li>
        <li fxHide fxShow.gt-xs *ngIf="isLoggedIn">
          <a class="nav-link" routerLink="{{navInfo.get('settings')!.url}}" [title]="navInfo.get('settings')!.title">
            <span class="nav-link-inner">{{ navInfo.get('settings')!.title }}</span>
          </a>
        </li>
        <li fxHide fxShow.gt-xs *ngIf="isLoggedIn">
          <a class="nav-link" (click)="logout()" [title]="navInfo.get('logout')!.title">
            <span class="nav-link-inner">{{ navInfo.get('logout')!.title }}</span>
          </a>
        </li>
        <li fxHide fxShow.gt-xs *ngIf="!isLoggedIn">
          <a class="nav-link" routerLink="{{navInfo.get('login')!.url}}" [title]="navInfo.get('login')!.title">
            <span class="nav-link-inner">{{ navInfo.get('login')!.title }}</span>
          </a>
        </li>
      </ul>
    </div>
    <button mat-button class="hamburger" aria-label="hamburger menu" (click)="doMenuClick()">
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>