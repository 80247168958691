<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
        <mat-card-header>
            <mat-card-title>Verify Email</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <br>
            <h3>The last step is to verify your email before you may post a course review.</h3>
            <a mat-stroked-button color="primary" href="https://outlook.office.com/mail/inbox">
                Office 365 Web Email
            </a>
            <a mat-stroked-button color="accent" (click)="resendVerification()">
                Resend Verification Email
            </a>
        </mat-card-content>
    </mat-card>
</div>
