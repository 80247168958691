<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Logged Out</mat-card-title>
        </mat-card-header>
        <mat-card-content style="text-align: center;">
            <br>
            <h3>You have been successfully logged out.</h3>
            <button routerLink="/" mat-button class="go-home-btn">This page will 
            automatically redirect to the home page in {{timeRemaining}} seconds.</button>
        </mat-card-content>
    </mat-card>
</div>
