<div class="content restrict-width">
  <div class="review-container">
    <div *ngIf="!reviewData.length && loading">
      <mat-progress-spinner mode="indeterminate" diameter="80" style="margin: auto;">
      </mat-progress-spinner>
    </div>
    <div *ngIf="!reviewData.length && !loading && showEmptyMsg">
      No reviews exist for this class yet. Be the first to leave one!
    </div>
    <mat-card *ngFor="let review of reviewData">
      <mat-card-content>
        <div class="rev-card-header">
          <div class="left">
            <span *ngIf="links" class="rev-course">
              <a [routerLink]="courseRouterLink(review)">
                {{review.ClassName}}
              </a>
            </span>
            <span *ngIf="!links" class="rev-course">
              {{review.ClassName}}
            </span>
            <mat-chip-list>
              <mat-chip class="chip-outlined" disabled>{{review.semester}} {{review.year}}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="card-header-buttons">
            <button mat-icon-button (click)="openSnackBar('Link copied to clipboard.', review.reviewId)"
             aria-label="copy review link to clipboard.">
              <mat-icon matTooltip="Copy link">link</mat-icon>
            </button>
            <button *ngIf="review.userId === userData?.uid" mat-icon-button
              routerLink="/review/edit/{{review.reviewId}}" aria-label="edit review">
              <mat-icon matTooltip="Edit review">mode_edit</mat-icon>
            </button>
            <!-- <button *ngIf="review.userId === userData?.uid" mat-icon-button (click)="removeReview(review.reviewId)">
              <mat-icon matTooltip="Delete review">delete</mat-icon>
            </button> -->
          </div>
        </div>
        <div class="rev-header">
          <p>
            <span class="rev-title">{{review.title}}</span><br>
            <span class="rev-subtitle">{{review.timestamp.toMillis() | date: 'M/d/yyyy, h:mm a'}}</span>
          </p>
        </div>
        <p style="white-space: pre-line;">{{review.review}}</p>
        <mat-card-footer>
          <app-review-helpfulness [isLoggedIn]="isLoggedIn" [reviewId]="review.reviewId"
            [value]="getFeedbackValue(review.reviewId!)" [positive]="review.helpfulPositive"
            [negative]="review.helpfulNegative"></app-review-helpfulness>
          <div fxFlex></div>
          <mat-chip-list>
            <mat-chip class="chip-outlined hide-under-xs" [class]="'diff_'+review.difficulty" disabled
              matTooltip="Difficulty">
              {{review.difficultyString}}</mat-chip>
            <mat-chip class="chip-outlined" [class]="'rat_'+review.rating" disabled matTooltip="Rating">
              {{review.ratingString}}</mat-chip>
            <mat-chip class="chip-outlined hide-under-xs" disabled matTooltip="Workload">{{review.workload}} hrs/wk
            </mat-chip>
          </mat-chip-list>
        </mat-card-footer>
      </mat-card-content>
    </mat-card>
  </div>
</div>