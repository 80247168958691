<div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="16px"
    class="filter-container">
    <div class="filter" fxFlex>
      <mat-form-field appearance="fill">
        <mat-label>Department Code</mat-label>
        <input
          matInput
          [formControl]="deptControl"
          (ngModelChange)="onDepartmentChange($event.value)"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredDeptOptions | async" [value]="option.value">
            {{ option.view }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="filter" fxFlex>
      <mat-form-field appearance="fill">
        <mat-label>Course Level</mat-label>
        <mat-select [(value)]="selectedCourseLevel" (selectionChange)="onCourseLevelChange($event.value)">
          <mat-option *ngFor="let level of courseLevelOptions" [value]="level">
            {{level}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<mat-card class="chart-card">
  <canvas #chartCanvas></canvas>
</mat-card>
