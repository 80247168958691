<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{courseName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content style="text-align: center;">
      <br>
      <form class="user-edit-form" [formGroup]="editForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="form-field-full-width">
          <mat-label>Department</mat-label>
          <mat-select formControlName="Department" multiple required>
            <mat-option *ngFor="let Department of categories" [value]="Department">{{Department | titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox id="spring" formControlName="seasonSpring"
        style="margin-right: 3rem;">Spring</mat-checkbox>
        <mat-checkbox id="summer" formControlName="seasonSummer"
        style="margin-right: 3rem;">Summer</mat-checkbox>
        <mat-checkbox id="fall" formControlName="seasonFall">Fall</mat-checkbox>
        <mat-divider [inset]="true"></mat-divider>

        <mat-form-field class="form-field-full-width">
          <mat-label>Languages</mat-label>
          <mat-select formControlName="languages" multiple>
            <mat-option *ngFor="let language of languages" [value]="language">{{language}}</mat-option>
          </mat-select>
        </mat-form-field>
        <br><button mat-stroked-button color="primary">Save Changes</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>