<div class="content">
  <div class="filter-cards">
    <mat-card>
      <mat-card-subtitle>Filter by MCS Area:</mat-card-subtitle>
      <mat-chip-list multiple>
        <mat-chip [selected]="isSelected(Department)" (click)="toggleSelection(Department)"
          *ngFor="let Department of depts">
          {{Department}}
        </mat-chip>
      </mat-chip-list>
    </mat-card>
    <mat-card>
      <mat-card-subtitle>Filter by MCS-DS Area:</mat-card-subtitle>
      <mat-chip-list multiple>
        <mat-chip [selected]="isSelected(Department)" (click)="toggleSelection(Department)"
          *ngFor="let Department of mcsdsCats">
          {{Department}}
        </mat-chip>
      </mat-chip-list>
    </mat-card>
    <mat-card>
      <mat-card-subtitle>Filter by Language:</mat-card-subtitle>
      <mat-chip-list multiple>
        <mat-chip [selected]="isSelected(language)" (click)="toggleSelection(language)"
          *ngFor="let language of languages">
          {{language}}
        </mat-chip>
      </mat-chip-list>
    </mat-card>
  </div>
  <div class="course-grid-container">
    <mat-card *ngFor="let class of visibleClasses" [routerLink]="getRouterLink(class)">
      <mat-card-header>
        <mat-card-title>{{class.ClassName}}</mat-card-title>
        <mat-card-subtitle>{{class.CourseNumber}}</mat-card-subtitle>
      </mat-card-header>
      <mat-chip-list>
        <mat-chip *ngFor="let cat of class.Department">{{cat}}&nbsp;
        </mat-chip>
        <mat-chip *ngFor="let lang of class.languages">{{lang}}</mat-chip>
      </mat-chip-list>
    </mat-card>
  </div>
</div>