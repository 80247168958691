export const environment = {
    websiteName: "illini.courses", 
    production: true, 
    firebase: {
        apiKey: "AIzaSyAklMrJioaJPCkU6pT8W7Yq0IfsQ0h3o88",
        authDomain: "uiuc-course-hub.firebaseapp.com",
        databaseURL: "https://uiuc-course-hub-default-rtdb.firebaseio.com",
        projectId: "uiuc-course-hub",
        storageBucket: "uiuc-course-hub.appspot.com",
        messagingSenderId: "74220647977",
        appId: "1:74220647977:web:ab544bacd4aa400960ce38",
        measurementId: "G-PWWS2YBZE0"
    }
};
