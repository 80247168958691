<div fxLayout="row" fxLayoutAlign="center center">
  <div class="col-form basic-container slim">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Reset your password</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="full-width-form" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
          <div class="form-row">
            <label for="email">Email</label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="email" id="email"
                [ngClass]="{'is-invalid': submitted || resetForm.controls.email.errors}">
              <mat-error *ngIf="resetForm.controls.email.hasError('required')">Please enter your email</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="error" class="alert">{{error}}</div>
          <button [disabled]="loading" mat-raised-button color="primary" class="btn-block">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Continue
          </button>
        </form>
        <span style="margin-top: 1.5rem" fxLayout="row" fxLayoutAlign="center center">
          <a routerLink="/login">Return to login
          </a>
        </span>
      </mat-card-content>
    </mat-card>
    <br>
    <br>
    <span style="padding-left: 1.5rem;">
      <span>
        <span>Don't have an account?
        </span>
      </span>
      <a routerLink="/register">Sign up
      </a>
    </span>
  </div>
</div>