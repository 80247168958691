<div class="helpful-container">
    <div class="helpful-label">
        <span>Was this review helpful?</span>
        <span class="helpful-vote-container">(
            <span class="helpful-vote-positive">+{{positive}}</span>
            /
            <span class="helpful-vote-negative">-{{negative}}</span>
            )
        </span>
    </div>
    <mat-button-toggle-group [multiple]="true" name="helpful" 
            aria-label="Helpful" 
            [disabled]="!isLoggedIn"
            matTooltip="Must be logged in."
            [matTooltipDisabled]="isLoggedIn"
            [value]="value"
            (change)="sendReviewFeedback($event)">
        <mat-button-toggle value="yes">Yes</mat-button-toggle>
        <mat-button-toggle value="no">No</mat-button-toggle>
    </mat-button-toggle-group>
</div>