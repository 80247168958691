<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Profile</mat-card-title>
      <div fxFlex></div>
      <div class="card-header-buttons">
        <!-- <button mat-icon-button routerLink="/user/edit"> -->
        <!-- <button mat-icon-button (click)="onEditClick()">
          <mat-icon matTooltip="Edit profile">mode_edit</mat-icon>
        </button> -->
        <span class="buttons">
          <button mat-raised-button *ngIf="!editing" (click)="onEditClick()" [disabled]="editing ? true: null"
            [disableRipple]="true">
            Edit</button>
          <button mat-raised-button *ngIf="editing" (click)="onCancelEditClick()" [disableRipple]="true">
            Cancel</button>
          <button mat-raised-button *ngIf="editing" (click)="onSubmit()" color="primary"
            [disabled]="!editing ? true: null" [disableRipple]="true">
            Save</button>
        </span>
      </div>
    </mat-card-header>
    <mat-card-content>
      <br>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="text" disabled matInput placeholder="Email" value="{{userInfo.a_email.value}}">
      </mat-form-field>
      <form class="user-edit-form" [formGroup]="editUserDataForm">
        <mat-form-field>
          <mat-label>{{ userInfo['b_firstName'].displayName }}</mat-label>
          <input type="text" matInput placeholder="First Name" value="{{userInfo['b_firstName'].value}}"
            formControlName="{{userInfo['b_firstName'].name}}">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ userInfo['c_lastName'].displayName }}</mat-label>
          <input type="text" matInput placeholder="Last Name" value="{{userInfo['c_lastName'].value}}"
            formControlName="{{userInfo['c_lastName'].name}}">
        </mat-form-field>
        <mat-form-field>
          <mat-label>First Semester</mat-label>
          <mat-select formControlName="firstSemester">
            <mat-option [value]="semester" *ngFor="let semester of Semesters">
              {{semester}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <br>
      <div *ngIf="!reviewData.length">
        <button mat-raised-button color="primary" routerLink="/createReview"
         class="no-reviews-button">
          Write your first review
        </button>
      </div>
      <mat-card-header *ngIf="reviewData.length">
        <mat-card-title>Reviews</mat-card-title>
        <div fxFlex></div>
      </mat-card-header>
      <mat-nav-list>
        <mat-list-item *ngFor="let review of reviewData" routerLink="/review/{{review.reviewId}}">
          <span style="min-width: 0;">
            <span matLine>{{ review.ClassName }}</span>
            <span matLine>{{ review.title }}</span>
          </span>
          <div fxFlex></div>
          <div class="list-buttons" (click)="$event.stopPropagation()">
            <!-- <button mat-icon-button (click)="openSnackBar('Link copied to clipboard.')">
              <mat-icon>link</mat-icon>
            </button> -->
            <button mat-icon-button routerLink="/review/edit/{{review.reviewId}}">
              <mat-icon>mode_edit</mat-icon>
            </button>
            <button mat-icon-button (click)="removeReview(review.reviewId)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>
</div>